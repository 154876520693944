.price__container {
    width: 80%;
    margin: 0 auto 0 auto;
    background-color: #f1f3f6;
    max-width: 1920px;
    padding-top: 148px;
}
.price__container__main {
    width: 80%;
    margin: 0 auto 0 auto;
    background-color: #fff;
    max-width: 1920px;
    padding-top: 148px;
}
.price__mobile__container {
    width: 100%;
    padding: 0 20px;
    background-color: #f1f3f6;
    max-width: 1920px;
    padding-top: 140px;
}
.price__mobile__container h1 {
    text-transform: capitalize;
}
.price__grid {
    display: grid;
    grid-template-columns: repeat(1fr, 9);
}
.price__grid div:nth-of-type(1) {
    grid-column: 1/2;
    grid-row: 1/3;
}
.price__grid div:nth-of-type(2) {
    grid-column: 2/10;
    grid-row: 1/3;
}
.price__one {
    padding: 20px 0;
}
.price__one h3 {
    padding: 20px 10px;
    border-bottom: 1px solid rgb(212, 212, 212);
}
.price__one h4 {
    font-weight: 500;
    color: #000;
}
.bePrice__filterItem {
    padding: 18px 0;
}
.bePrice__filterItem button {
    background-color: #ff1616 !important;
}
input[type="text"],
select {
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
    outline: none;
}
.prices__mainSection {
    padding: 5px 0;
}
.prices__mainSection h1 {
    background-color: #ff1616;
    color: white;
    font-weight: 500;
    padding: 5px 10px;
    font-size: 20px;
    display: block;
    margin-bottom: 10px;
}
.prices__mainSection h2 {
    background-color: #ff1616;
    color: white;
    font-weight: 500;
    padding: 5px 10px;
    font-size: 20px;
    display: block;
    margin-bottom: 10px;
}
.price__one h2 {
    border-bottom: 1px solid #dee2e6;
    width: 95%;
    color: #000;
    font-size: 21px;
    padding: 5px 10px;
    text-transform: capitalize;
    font-weight: 500;
}
.price__itemGrid {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
}
.price__rs {
    font-size: 18px;
    font-weight: 600;
    color: #ff1616;
}
.prices__mobileSection {
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
    background-color: #fff;
    border: 1px solid #dee2e6;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    width: 230px;
    height: 246px;
    margin: 0 3px 6px 0;
}
.prices__mobileSection img {
    width: 80px;
}
.prices__mobileSection p {
    text-align: center;
    font-size: 15px;
}
.prices__mobileSection__Mobiles {
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
    background-color: #fff;
    border: 1px solid #dee2e6;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    width: 200px;
    height: 246px;
    margin: 0 3px 6px 0;
}
.prices__mobileSection__Mobiles img {
    width: 80px;
}
.prices__mobileSection__Mobiles p {
    text-align: center;
    font-size: 15px;
}
.price__barColor1 {
    border: 1px solid rgb(212, 212, 212);
    width: 100%;
}
.price__blockHeading {
    position: relative;
    margin-top: 28px;
    margin-left: 5px;
    border-bottom: 0px solid #c6c6c6;
}
.price__blockHeading h1 {
    position: absolute;
    bottom: -21px;
    left: -4px;
    padding: 10px;
    background-color: #fff;
    color: #181716;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-size: 20px;
}
.price__techExplore {
    display: flex;
    justify-content: flex-end;
}
.price__techExplore h4 {
    background-color: #ff1616;
    margin: 5px;
    padding: 5px 10px;
    color: #ffffff !important;
}
.price__techExplore a {
    color: #ffffff !important;
    font-size: 16px;
    font-weight: 400;
}
.price__techExplore h4:hover {
    color: #ffffff;
    opacity: 0.9;
    cursor: pointer;
}
.price__filter {
    display: flex;
    flex-direction: row;
}
.mobiel__price__main {
    background-color: #f1f3f6;
}
.price__upperData {
    display: grid;
    grid-template-columns: repeat(1fr, 9);
    padding: 15px 0;
}
.price__upperData div:nth-of-type(1) {
    grid-column: 1/8;
}
.price__upperData div:nth-of-type(2) {
    grid-column: 9/10;
}
.mobile__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.mobile__filter {
    padding: 10px;
    background-color: #fff;
    border-radius: 10px;
    margin: 0 4px 0 0;
    flex: 1;
}
.mobile__filter span {
    text-transform: uppercase;
    background-color: #ff1616;
    color: white;
    font-weight: 500;
    padding: 5px 10px;
    font-size: 20px;
    display: block;
}
.mobile__list {
    flex: 2;
}
.mobile__description {
    background-color: #ffffff;
    padding: 15px;
    border-radius: 10px;
}
.mobile__description h1 {
    font-weight: 600;
    font-size: 22px;
}
.mobile__famous__container h2 {
    background-color: #ff1616;
    color: white;
    font-weight: 500;
    padding: 5px 10px;
    font-size: 22px;
}
.mobile__famous__container h3 {
    font-size: 16px;
}
.mobile__famous {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #e9e9e9;
}
.mobile__famous p:nth-child(1) {
    font-size: 10px !important;
    text-transform: capitalize;
}
.mobile__famous p {
    font-size: 13px !important;
    text-transform: capitalize;
    margin: 5px 0;
}
.mobile__famous p:nth-of-type(2) {
    font-size: 13px;
    text-align: right;
}
.mobile__price__filter {
    list-style-type: none;
}
.mobile__price__filter li {
    padding: 5px;
}
.mobile__price__filter li {
    padding: 5px;
}
.mobile__price__group {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.mobile__price__group p {
    padding: 0 5px;
    text-transform: capitalize;
    font-size: 13px;
}
.mobile__dropdown option {
    outline: none;
    width: 20px;
}
.mobile__price__search {
    background-color: #ff1616;
    font-size: 16px;
    text-transform: uppercase;
}
.price__view__container {
    padding-top: 140px;
    margin: 0 auto;
    max-width: 1920px;
    width: 80%;
}
.price__view__image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 80px;
    border: 1px solid #e9e9e9;
    border-radius: 10px;
}
.price__view__image h2 {
    text-align: center;
}
.price__view__uppercontainer {
    display: flex;
    flex-direction: row;
    margin: 20px 10px 10px 10px;
}
.price__view__mainImage {
    width: 160px;
}
.price__view__specs__detail {
    display: flex;
    flex-direction: row;
    width: 200px;
    align-items: center;
}
.price__view__specs__detail .fas {
    padding: 12px;
    border: 3px solid black;
    border-radius: 10px;
    font-size: 28px;
    width: 67px;
    text-align: center;
}
.price__view__specs__detail .fab {
    padding: 12px;
    border: 3px solid black;
    border-radius: 10px;
    font-size: 28px;
    width: 67px;
    text-align: center;
}
.price__view__specs__detail div:nth-of-type(1) {
    margin: 0 10px;
    width: 66.67px;
}
.price__view__specs__detail__packages {
    display: flex;
    flex-direction: row;
    margin: 30px;
    align-items: center;
}
.price__view__specs__detail__packages .fas {
    padding: 12px;
    border: 3px solid black;
    border-radius: 10px;
    font-size: 28px;
    width: 67px;
    text-align: center;
}
.price__view__specs__detail__packages .fab {
    padding: 12px;
    border: 3px solid black;
    border-radius: 10px;
    font-size: 28px;
    width: 67px;
    text-align: center;
}
.price__view__specs__detail__packages div:nth-of-type(1) {
    margin: 0 10px;
    width: 66.67px;
}
.price__view__second__p {
    font-size: 12px;
    font-weight: 600;
    color: #636363;
}
.price__view__specs {
    display: grid;
    grid-template-columns: auto auto auto;
    margin: 0 20px 0 20px;
}
.price__view__latest h2 {
    text-transform: uppercase;
    background-color: #ff1616;
    color: white;
    font-weight: 500;
    padding: 5px 10px;
    font-size: 22px;
    display: block;
}
table {
    border-radius: 10px;
    border-width: 0.1px;
    border-style: solid;
    border-color: #dddddd;
    border-spacing: 0;
    border-collapse: separate;
    width: 100%;
    padding: 10px;
}
td,
th {
    text-align: left;
    padding: 8px;
}
tr:nth-child(even) {
    background-color: #f7f7f7;
}
.price__view__th {
    color: #ff1616;
    font-size: 20px;
}
.view__th {
    color: #16b05e;
    font-size: 17px;
    border-bottom: 1px solid #dddddd;
}
.view__th {
    text-align: center !important;
    border-collapse: collapse;
    padding: 8px;
}
.price__view__table__specs h2 {
    margin: 10px 0;
}
.price__view__table {
    margin: 10px 0;
}
.view__table {
    margin: 10px 0;
}
.Social-position1 {
    position: -webkit-sticky;
    position: sticky;
    top: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
    align-content: center;
}
.price__view__compare {
    background-color: #ff1616;
    padding: 15px;
    grid-row: 4/4;
    grid-column: 1/4;
    height: 50px;
    border-radius: 30px;
}
.price__view__compare ul {
    text-align: center;
}
.price__view__compare ul a li {
    align-items: center;
    color: white;
    list-style-type: none;
    text-transform: capitalize;
}
.price__view__compare ul a li:hover {
    color: #c6c6c6 !important;
}
.price__mobile__link a {
    font-size: 15px;
    color: #606060;
}
.price__mobile__link span {
    font-size: 15px;
    color: #606060;
}
.mobile__choice__filter {
    padding: 10px;
    background-color: #fff;
    border-radius: 10px;
    margin-bottom: 10px;
    height: 440px;
}
.mobile__choice__filter span {
    text-transform: uppercase;
    background-color: #ff1616;
    color: white;
    font-weight: 500;
    padding: 5px 10px;
    font-size: 20px;
    display: block;
}
.mobile__choice__filter ul {
    padding: 5px 20px;
}
.mobile__choice__filter ul li {
    padding: 4px 0;
}
.mobile__choice__filter ul li a {
    font-size: 16px !important;
}
.mobiles__brands__exp {
    text-align: justify;
    padding: 5px 0;
}
.price__mobile__link {
    margin: 0;
}
.pc__table {
    display: block;
}
.mobile__table {
    display: none;
}
@media (max-width: 1300px) {
    .price__itemGrid {
        display: grid;
        grid-template-columns: auto auto auto auto auto;
    }
}
@media (max-width: 1200px) {
    .price__container {
        padding-top: 0;
    }
    .price__container__main {
        padding-top: 0;
        width: 90%;
    }
    .price__itemGrid {
        grid-template-columns: auto auto auto auto auto auto;
    }
    .prices__mobileSection {
        display: flex;
        flex-direction: column;
        padding: 18px 3px;
        background-color: #fff;
        align-items: center;
    }
    .prices__mobileSection__Mobiles {
        display: flex;
        flex-direction: column;
        padding: 18px 3px;
        background-color: #fff;
        align-items: center;
    }
    .price__view__uppercontainer {
        display: flex;
        flex-direction: column;
        margin: 20px 10px 10px 10px;
    }
    .price__view__specs {
        grid-template-columns: auto auto auto;
    }
    .price__view__compare {
        grid-row: 5/5;
        grid-column: 1/4;
        margin-bottom: 20px;
    }
    .price__view__container {
        padding-top: 0px;
    }
    .price__mobile__container {
        padding-top: 20px;
    }
    .mobile__filter {
        padding: 10px;
        grid-column: 0/0;
        background-color: #fff;
        height: 2225px;
    }
    .mobile__container {
        flex-direction: column;
    }
    .price__view__specs__detail {
        margin: 10px 0;
        width: 66.67px;
    }
    .price__view__specs__detail__packages {
        margin: 10px 0;
        width: 66.67px;
    }
}
@media (max-width: 1000px) {
    .price__itemGrid {
        grid-template-columns: auto auto auto auto;
    }
    .prices__mobileSection {
        display: flex;
        flex-direction: column;
        padding: 18px 3px;
        background-color: #fff;
        align-items: center;
    }
    .prices__mobileSection__Mobiles {
        display: flex;
        flex-direction: column;
        padding: 18px 3px;
        background-color: #fff;
        align-items: center;
    }
    .price__mobile__link {
        margin: 10px 0 0 0;
    }
}
@media (max-width: 700px) {
    .pc__table {
        display: none;
    }
    .mobile__table {
        display: block;
    }
    .view__table__color {
        color: #16b05e;
        font-size: 18px !important;
        font-weight: 900;
    }
    .price__itemGrid {
        grid-template-columns: auto auto auto;
    }
    .prices__mobileSection {
        display: flex;
        flex-direction: column;
        padding: 18px 3px;
        background-color: #fff;
        align-items: center;
    }
    .prices__mobileSection__Mobiles {
        display: flex;
        flex-direction: column;
        padding: 18px 3px;
        background-color: #fff;
        align-items: center;
    }
    .price__view__specs {
        grid-template-columns: auto auto;
        margin: 0;
    }
    .price__view__compare {
        grid-row: 5/5;
        grid-column: 1/3;
    }
    table {
        padding: 0;
    }
}
@media (max-width: 500px) {
    .price__itemGrid {
        grid-template-columns: auto auto;
    }
    .prices__mobileSection {
        display: flex;
        flex-direction: column;
        padding: 18px 3px;
        background-color: #fff;
        border: 1px solid #dee2e6;
        align-items: center;
        border-radius: 10px;
        max-width: 152px;
        margin: 3px 4px 4px 4px;
    }
    .prices__mobileSection__Mobiles {
        display: flex;
        flex-direction: column;
        padding: 18px 3px;
        background-color: #fff;
        border: 1px solid #dee2e6;
        align-items: center;
        border-radius: 10px;
        max-width: 152px;
        margin: 3px 4px 4px 4px;
    }
    .price__view__container {
        padding-top: 0px;
        margin: 0 auto;
        max-width: 1920px;
        width: 90%;
    }
    .price__view__specs__detail {
        margin: 10px 0;
        width: 66.67px;
    }
    .price__view__specs__detail__packages {
        margin: 10px 0;
        width: 66.67px;
    }
    .price__upperData {
        padding: 0px 0;
    }
    .show__image {
        display: none;
    }
}
