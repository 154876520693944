*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #000000c5;
  font-family: 'Montserrat', sans-serif;

}
.container{
  width: 100%;
  margin: 0 auto;
  max-width: 1920px;
  /* height: 100vh; */
  /* border: 1px solid; */
  display: flex;
justify-content: center;
align-items: center;

}
.search__inner{
  border-radius: 10px;
  width: 25em;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  margin: 100px 10px;
}
.search__inner h1{
  text-align: center;
font-size: 27px;
padding: 10px;
}
.search__inner form{
 display: flex;
 flex-direction: column;
}
select{
  margin: 10px;
  outline: none;
  border: 1px solid;
  width: 60%;
  /* border-radius: 14px; */
  height: 26px;
  border-radius: 4px;
  border: 1px solid #c3c3c3;
}
h4{
  font-size: 19px;
  padding: 10px 0;
}
button{
  margin: 12px;
  height: 25px;
  background-color: #3498DB;
  outline: none;
  border: none;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
}
table{
  margin: 10px 0;
}
.button{
  /* margin: 12px; */
  /* height: 25px; */
  background-color: #3498DB;
  outline: none;
  border: none;
  color: #fff;
  font-weight: 600;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;

}
th{
  width: 20px;
}
td{
  width: 20px;
}
.search_-inner h1{
  margin: 20px 33px;
  font-size: 26px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

h2{
  text-align: center;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: white;
  padding: 30px 0;
}

/* Table Styles */

.table-wrapper{
  margin: 10px 70px 70px;
  box-shadow: 0px 35px 50px rgba( 0, 0, 0, 0.2 );
}

.fl-table {
  border-radius: 5px;
  font-size: 12px;
  font-weight: normal;
  border: none;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
  background-color: white;
}

.fl-table td, .fl-table th {
  text-align: center;
  padding: 8px;
}

.fl-table td {
  border-right: 1px solid #f8f8f8;
  font-size: 12px;
}

.fl-table thead th {
  color: #ffffff;
  background: #4FC3A1;
}


.fl-table thead th:nth-child(odd) {
  color: #ffffff;
  background: #324960;
}

.fl-table tr:nth-child(even) {
  background: #F8F8F8;
}
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td, #customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even){background-color: #f2f2f2;}

#customers tr:hover {background-color: #ddd;}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04AA6D;
  color: white;
}

/* Responsive */

@media (max-width: 767px) {
  .fl-table {
      display: block;
      width: 100%;
  }
  .table-wrapper:before{
      content: "Scroll horizontally >";
      display: block;
      text-align: right;
      font-size: 11px;
      color: white;
      padding: 0 0 10px;
  }
  .fl-table thead, .fl-table tbody, .fl-table thead th {
      display: block;
  }
  .fl-table thead th:last-child{
      border-bottom: none;
  }
  .fl-table thead {
      float: left;
  }
  .fl-table tbody {
      width: auto;
      position: relative;
      overflow-x: auto;
  }
  .fl-table td, .fl-table th {
      padding: 20px .625em .625em .625em;
      height: 60px;
      vertical-align: middle;
      box-sizing: border-box;
      overflow-x: hidden;
      overflow-y: auto;
      width: 120px;
      font-size: 13px;
      text-overflow: ellipsis;
  }
  .fl-table thead th {
      text-align: left;
      border-bottom: 1px solid #f7f7f9;
  }
  .fl-table tbody tr {
      display: table-cell;
  }
  .fl-table tbody tr:nth-child(odd) {
      background: none;
  }
  .fl-table tr:nth-child(even) {
      background: transparent;
  }
  .fl-table tr td:nth-child(odd) {
      background: #F8F8F8;
      border-right: 1px solid #E6E4E4;
  }
  .fl-table tr td:nth-child(even) {
      border-right: 1px solid #E6E4E4;
  }
  .fl-table tbody td {
      display: block;
      text-align: center;
  }
}
